<template>
  <dialog-outline v-model="showDialog">
    {{ dialogTitle }}
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- context -->
    <template v-slot:pre-context>
      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <template v-slot:context>
      <!-- Source -->
      <v-subheader>
        Source
      </v-subheader>
      <p class="ml-8 text-body-2 " style="color: #2b2b2b">
        <v-icon :color="recordIconColorClass(sourceRecord)">
          {{ iconItem }}</v-icon
        >
        &nbsp; &nbsp;
        {{ sourceRecordName }}
      </p>
      <v-divider></v-divider>

      <!-- Destination -->
      <v-subheader>
        Destination
      </v-subheader>
      <p class=" ml-8 text-body-2 " style="color: #2b2b2b">
        <v-icon :color="recordIconColorClass(destinationRecord)">
          {{ getDestinationIcon }}</v-icon
        >
        &nbsp;&nbsp;
        {{ destinationRecordName }}
      </p>
      <v-divider></v-divider>

      <!-- Item Template -->
      <div v-show="visibleItemTemplate">
        <v-subheader>
          Please Select Item Template
        </v-subheader>
        <v-select
          class="ml-8 mr-8"
          v-model="defaultTemplate"
          :items="agendaItemTemplates"
          item-text="name"
          item-value="index"
          label="Item Template"
          @change="onTemplateChanged"
          return-object
        ></v-select>
        <v-divider></v-divider>
      </div>

      <!-- Defer Options -->
      <v-subheader>
        Defer Options
      </v-subheader>
      <expansion-panels-layout :inset="true">
        <!-- header -->
        <template v-slot:header>
          Defer Options &emsp;
          <v-chip-group column show-arrows>
            <v-chip :small="visibleOnSmAndDown">{{
              deferOptionsTitle()
            }}</v-chip>
          </v-chip-group>
        </template>
        <template v-slot:content>
          <radio-group
            @change="onOptionChange"
            :options="options"
            :select-option="selectedOption.value"
          >
          </radio-group>
        </template>
      </expansion-panels-layout>
    </template>

    <!-- Dialog Actions -->
    <template v-slot:actions>
      <base-button @click="onOk" :disabled="!isTemplateSelected">
        {{ dialogOkCommandLabel }}
      </base-button>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { actionResultType } from "@/model/action/actionModel";
import {
  agendaItemOperation,
  agendaItemToItemReferDeferOptions,
  agendaItemToSectionReferDeferOptions,
  findAgendaItemOperation,
  findAgendaItemToItemReferDeferOption,
  findAgendaItemToSectionReferDeferOption
} from "@/model/agenda/item/agendaItemModel";
import { recordBannerAble } from "@/mixins/shared/bannerAble/recordBannerAble";
import { recordType } from "@/model/record/recordModel";
import { recordIconMixin } from "@/mixins/shared/record/recordIconMixin";
import { iconAgendaItem, iconAgendaSection } from "@/design/icon/iconConst";

export default {
  name: "AgendaItemDeferDialog",
  mixins: [dialogOutlineMixin, recordBannerAble, recordIconMixin],
  components: {
    RadioGroup: () => import("@/components/shared/core/radio/RadioGroup.vue"),
    ExpansionPanelsLayout: () =>
      import(
        "@/components/shared/core/expansionPanel/ExpansionPanelsLayout.vue"
      )
  },
  props: {
    sourceRecord: undefined,
    destinationRecord: undefined,
    agendaItemTemplates: {
      type: Array,
      default: () => []
    },
    deferEvent: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedOption: undefined,
      options: agendaItemToItemReferDeferOptions,
      defaultTemplate: undefined,
      iconSection: iconAgendaSection,
      iconItem: iconAgendaItem
    };
  },

  computed: {
    /**
     * Defer Operation
     * @return {{name: string, icon: string, label: string}} Defer Operation
     */
    operation() {
      return findAgendaItemOperation(agendaItemOperation.defer);
    },

    /**
     * Defer operation Label
     * @return {string} Defer operation Label
     */
    operationLabel() {
      return this.operation?.label;
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.operation?.icon;
    },

    /**
     * Get Defer Item Dialog title
     * @return {string} Defer item Dialog title
     */
    dialogTitle() {
      return `${this.operationLabel} Agenda Item`;
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operationLabel} Item ${this.sourceRecord?.name}`;
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return this.operationLabel;
    },

    /**
     * Source Record Name
     * @return {string}
     */
    sourceRecordName() {
      return this.sourceRecord?.name;
    },

    /**
     * Destination Record Name
     * @return {string}
     */
    destinationRecordName() {
      return this.destinationRecord?.name;
    },

    /**
     * Destination Record Type
     * @return {String}
     */
    destinationRecordType() {
      return this.destinationRecord?.recordType;
    },

    /**
     * Verify if Template is Selected
     * @return {boolean}
     */
    isTemplateSelected() {
      return !!this.defaultTemplate;
    },

    /**
     * Get Destination Record Icon
     * @return {string}
     */
    getDestinationIcon() {
      return this.destinationRecord?.recordTypeId === recordType.SECTION
        ? this.iconSection
        : this.iconItem;
    },

    /**
     * Is Visible Agenda Item Template
     * @return {boolean}
     */
    visibleItemTemplate() {
      return this.agendaItemTemplates.length !== 1;
    }
  },

  methods: {
    /**
     * Performs Defer Item
     */
    async onOk() {
      try {
        this.clearAlert();
        const result = await this.deferEvent(
          this.selectedOption.value,
          this.defaultTemplate.index
        );
        if (result?.type === actionResultType.success) {
          await this.autoCloseDialog(result.message);
        } else {
          this.internalAlert = this.createAlert(
            this.alertTypeName.error,
            this.formatAlertError(this.action, result.message),
            false
          );
        }
      } catch (e) {
        this.internalAlert = this.createAlert(
          this.alertTypeName.error,
          this.formatAlertError(this.action, e),
          false
        );
      }
    },

    /**
     * Event On Insert Option Change
     * @param option
     */
    onOptionChange(option) {
      this.selectedOption =
        this.destinationRecord?.recordTypeId === recordType.SECTION
          ? findAgendaItemToSectionReferDeferOption(option)
          : findAgendaItemToItemReferDeferOption(option);
    },

    /**
     * Set Refer Options
     */
    deferOptions() {
      this.options =
        this.destinationRecord?.recordTypeId === recordType.SECTION
          ? agendaItemToSectionReferDeferOptions
          : agendaItemToItemReferDeferOptions;
    },

    /**
     * Event On Template Changed
     * @param template
     */
    onTemplateChanged(template) {
      this.defaultTemplate = template;
    },

    /**
     * Refer Option Title
     * @return {String}
     */
    deferOptionsTitle() {
      return this.selectedOption.text;
    }
  },
  updated() {
    //Set Defer Option when Dialog loads
    this.deferOptions();

    //Set Default Insert Option when Dialog loads
    this.selectedOption =
      this.destinationRecord?.recordTypeId === recordType.SECTION
        ? agendaItemToSectionReferDeferOptions[1]
        : agendaItemToItemReferDeferOptions[2];

    //Set Default Template when Dialog loads
    this.defaultTemplate = this.agendaItemTemplates[0];
  }
};
</script>
